<template>
  <div>
    <div class="i-layout-page-header header-title">
      <span class="ivu-page-header-title">{{ $route.meta.title }}</span>
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Button type="primary" @click="handleCreateMemberRight">添加权益</Button>
      <Table
        class="l-m-t25"
        :columns="columns"
        :data="dataList"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="image">
          <div class="image-wrap" v-viewer>
            <img v-lazy="row.image" />
          </div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <i-switch
            v-model="row.status"
            :value="row.status"
            :true-value="1"
            :false-value="0"
            size="large"
            @on-change="statusChange(row)">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </template>
        <template slot-scope="{ row }" slot="action">
          <a @click="handleEdit(row)">编辑</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" :page-size="searchWhere.limit" show-elevator show-total @on-change="handlePageChange" />
      </div>
    </Card>

    <Modal v-model="modal1" :title="title + '会员权益'" :z-index="555" :mask-closable="false" @on-cancel="handleCancel">
      <Form ref="form" :model="form" :rules="rules" :label-width="80">
        <Input v-model="form.id" style="display: none"></Input>
        <Input v-model="form.status" style="display: none"></Input>
        <Input v-model="form.right_type" style="display: none"></Input>
        <FormItem label="权益名称" prop="title">
          <Input v-model.trim="form.title" placeholder="请输入权益名称"></Input>
        </FormItem>
        <FormItem label="展示名称" prop="show_title">
          <Input v-model.trim="form.show_title" placeholder="请输入展示名称"></Input>
        </FormItem>
        <FormItem label="权益图标" prop="image">
          <div class="image-group" @click="callImage">
            <img v-if="form.image" v-lazy="form.image" />
            <Icon v-else type="ios-camera-outline" size="26" />
          </div>
          <Input v-model="form.image" style="display: none"></Input>
        </FormItem>
        <FormItem label="权益简介" prop="show_title">
          <Input
            v-model.trim="form.explain"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            placeholder="请输入权益简介"
          ></Input>
        </FormItem>
        <FormItem
          v-show="form.right_type !== 'coupon' && form.right_type !== 'vip_price'"
          :label="
            form.right_type === 'offline' || form.right_type === 'express' || form.right_type === 'vip_price'
              ? '折扣数(%)'
              : '积分倍数'
          "
          prop="number">
          <InputNumber v-model="form.number" :min="1"></InputNumber>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" @click="handleFormSubmit('form')">提交</Button>
      </div>
    </Modal>
    <Modal v-model="modal2" width="950px" scrollable footer-hide closable title="选择权益图标" :z-index="888">
      <UploadPictures v-if="modal2" isChoice="单选" :gridBtn="gridBtn" :gridPic="gridPic" @getPic="getPic"></UploadPictures>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getUsersMemberRightApi,
  memberRightSave
} from '@/api/marketing'

export default {
  name: 'right',
  mixins: [mixins],
  data () {
    return {
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 10
      },

      // 表格数据
      dataList: [],
      columns: [
        { title: '权益名称', key: 'title' },
        { title: '展示名称', key: 'show_title' },
        { title: '权益图标', slot: 'image' },
        { title: '权益简介', key: 'explain' },
        { title: '权益状态', slot: 'status' },
        { title: '操作', slot: 'action', width: 80 }
      ],

      modal1: false,
      title: '添加',
      modal2: false,
      form: {
        id: 0,
        right_type: '',
        title: '',
        show_title: '',
        image: '',
        explain: '',
        number: 1,
        status: 1
      },
      rules: {
        title: [{ required: true, message: '请输入权益名称', trigger: 'blur' }],
        show_title: [{ required: true, message: '请输入展示名称', trigger: 'blur' }],
        image: [{ required: true, message: '请上传权益图标' }],
        explain: [{ required: true, message: '请输入权益简介', trigger: 'blur' }],
        number: [{ required: true, type: 'integer', message: '请输入正整数' }]
      }
    }
  },
  mounted () {
    this.getUsersMemberRightRequest()
  },
  methods: {
    // 获取显示资源
    getUsersMemberRightRequest () {
      this.loading = true
      getUsersMemberRightApi().then((res) => {
        const { count, list } = res.data
        this.loading = false
        this.total = count
        this.dataList = list
      }).catch((err) => {
        this.loading = false
        this.$Message.error(err)
      })
    },
    // 添加类型
    handleCreateMemberRight () {
      this.modal1 = true
      this.title = '添加'
    },
    // 编辑
    handleEdit (row) {
      this.modal1 = true
      this.title = '编辑'
      this.form.id = row.id
      this.form.status = row.status
      this.form.right_type = row.right_type
      this.form.title = row.title
      this.form.show_title = row.show_title
      this.form.image = row.image
      this.form.explain = row.explain
      this.form.number = row.number
    },
    // 点击取消
    handleCancel () {
      this.form = {
        id: 0,
        right_type: '',
        title: '',
        show_title: '',
        image: '',
        explain: '',
        number: 1,
        status: 1
      }
    },
    // 选择图片
    callImage () {
      this.modal2 = true
    },
    getPic (image) {
      this.form.image = image.att_dir
      this.modal2 = false
    },
    // 处理提交
    handleFormSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          memberRightSave(this.form).then((res) => {
            this.modal1 = false
            this.getUsersMemberRightRequest()
            this.$Message.success(res.msg)
            this.handleCancel()
          }).catch((err) => {
            this.$Message.error(err.msg)
          })
        } else {
          return false
        }
      })
    },
    // 处理分页搜索
    handlePageChange (index) {
    }
  }
}
</script>

<style scoped lang="scss">
.image-wrap {
  width: 36px;
  height: 36px;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
  }
}

.image-group {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #dcdee2;
  border-radius: 4px;

  &:hover {
    border-color: #57a3f3;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
